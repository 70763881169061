import React from "react"
import { getProjectMetadata } from "../../utils/projectMetadataUtil"
import { env } from "../utils/evnVariablesHandlers"

const metadata = getProjectMetadata(env.__PROJECT__)

const LinkMailToSupport = () => {
  const mailHref = `mailto:${metadata.supportEmail}`
  return <a href={mailHref}>{metadata.supportEmail}</a>
}

export default LinkMailToSupport
