import React from "react"
import Layout from "../components/layout"
import Group from "../components/Group"
import QAItem from "../components/qaItem"
import SEO from "../components/seo"
import { Routing, getTitle, getId } from "../utils/routing"
import ProjectName from "../components/ProjectName"
import ProjectLink from "../components/ProjectLink"
import LinkMailToSupport from "../components/LinkMailToSupport"
const RC = Routing.PRIVACY_POLICY.sub
const title = Routing.PRIVACY_POLICY.title

const PrivacyPolicy = ({ location }) => {
  return (
    <Layout location={location}>
      <SEO title={title} />
      <h1>{title}</h1>
      <Group id={getId(RC.INTRODUCTION)}>
        <h2>{getTitle(RC.INTRODUCTION)}</h2>
        <p>
          <ProjectName company /> (“
          <ProjectName />
          ,” “we,” “us” or the “Company”) recognizes that people who use{" "}
          <ProjectName />
          ’s Service (“you” or “Users”) value their privacy. This Privacy Policy
          details important information regarding the collection, use and
          disclosure of User information collected on <ProjectName />
          ’s fantasy sports website located at
          <ProjectName url />, and any other features, tools, materials, or
          other services (including co-branded or affiliated services) offered
          from time to time by <ProjectName /> or its affiliate companies (the
          “Service”). <ProjectName /> provides this Privacy Policy to help you
          make an informed decision about whether to use or continue using the
          Service.
        </p>
        <p>
          We at <ProjectName company /> respect privacy and are committed to
          protect the privacy of our Users. This Privacy Policy outlines our
          practices with respect to collecting, using and disclosing your
          information through your use of our peer-to-peer gaming exchange
          applications, and any other features, tools, materials, or other
          services (including co-branded or affiliated services) offered from
          time to time by <ProjectName /> or its affiliate companies (“the
          Services"), and of User information collected on <ProjectName />
          ’s fantasy sports website located at <ProjectLink strict />.
        </p>
        <p>
          <ProjectName /> provides this Privacy Policy to help you make an
          informed decision about whether to use or continue using the Services.
          By using the Services, you agree to the terms of this Privacy Policy
          and your continued use of the Services constitutes your ongoing
          agreement to this Privacy Policy. By accessing the Services, you are
          consenting to the information collection and use practices described
          in this Privacy Policy.
        </p>
        <p>
          This Privacy Policy is a part of our Terms of Use which are
          incorporated herein by reference. We encourage you to read the Privacy
          Policy carefully and use it to make informed decisions. Your use of
          the Service and any information you provide through the Service
          remains subject to the terms of this Privacy Policy and our Terms of
          Use, as each may be updated from time to time.
        </p>
        <p>
          Any questions, comments or complaints that you might have should be
          emailed to
          <LinkMailToSupport />
          or forwarded in writing to:
        </p>
        <p>
          <ProjectName company />
        </p>
        <p>
          <ProjectName address /> &nbsp; <LinkMailToSupport />
        </p>
      </Group>
      <Group id={getId(RC.INFORMATION_WE_COLLECT)}>
        <h2>{getTitle(RC.INFORMATION_WE_COLLECT)}</h2>
        <p>
          In order to enhance the functionality of our Services, <ProjectName />
          collects several types of data and information from our users.
        </p>
        <QAItem>
          <p>Personal Information</p>
          <p>
            By using our Service and by submitting information to us through
            your use of our Service then this Privacy Policy will apply.
            <strong>
              You provide certain personal information to <ProjectName /> when
              choosing to participate in the various activities such as
              registering for an account, participating in contests, posting
              messages, taking advantage of promotions, responding to surveys or
              subscribing to newsletters or other mailing lists.
            </strong>
          </p>
          <p>
            The personal information we collect from you generally may include:
          </p>
          <ul>
            <li>a. your name;</li>
            <li>b. email address;</li>
            <li>c. date of birth;</li>
            <li>d. credit card billing information;</li>
            <li>e. contacts you choose to submit;</li>
            <li>f. profile photo;</li>
            <li>g. username;</li>
            <li>h. location;</li>
            <li>i. your preferences;</li>
            <li>j. submissions to various competitions and promotions;</li>
            <li>k. responses to surveys;</li>
            <li>l. communications sent to us by you;</li>
            <li>
              m. your subscriptions to newsletters and services offered by us;
              and
            </li>
            <li>
              n. any other information you submit to <ProjectName /> when
              choosing to participate in various activities on the Service;
            </li>
          </ul>
        </QAItem>

        <QAItem>
          <h5> Identification Information</h5>
          <p>
            {" "}
            In addition to the above, we may need to verify your identity in
            order for you to use some aspects of the Service. For purposes of
            verification, we may also collect the following personal information
            from you (for compliance reasons, provision of this information,
            when requested, is a mandatory condition of using our Service):
          </p>
          <ul>
            <li>a. passport information;</li>
            <li>b. driving license information;</li>
            <li>c. other identification documents;</li>
            <li>d. permanent and temporary address;</li>
            <li>e. tax-related information; and</li>
            <li>
              f. other information as may be required to verify you in
              accordance with applicable laws and regulations.
            </li>
          </ul>
          <p>
            In addition, if you choose to log in, access or otherwise connect to
            <ProjectName />, or contact <ProjectName />, through a social
            networking service (such as Facebook), we may collect your user ID
            and user name associated with that social networking service, as
            well as any information you make public using that social networking
            service. We may also collect information you have authorized the
            social networking service to share with us (such as your user ID,
            public profile information, email address, birthday, friends list,
            and pages you have "liked").
          </p>
        </QAItem>

        <QAItem>
          <h5>Non-Identifiable Information</h5>
          <p>
            <ProjectName /> also collects un-identified and non-identifiable
            information pertaining to a user(s), which may be made available or
            gathered via your use of the Services (“Non-personal Information”).
            We are not aware of the identity of the user from which the
            Non-personal Information was collected. Non-personal Information
            which is being collected may include your username, aggregated usage
            information and technical information transmitted by your device,
            including certain software and hardware information (e.g. the type
            of browser and operating system your device uses, language
            preference, access time and the domain name of the website from
            which you linked to the Services; etc.)
          </p>
        </QAItem>

        <QAItem>
          <h5> Cookies Information</h5>
          <p>
            When you visit <ProjectName />
            ’s website, we may send one or more cookies - small files - to your
            computer which may enable us or others to uniquely identify your
            browser. <ProjectName /> uses both session cookies and persistent
            cookies. A persistent cookie remains after you close your browser.
            Persistent cookies may be used by your browser on subsequent visits
            to the site. Persistent cookies can be removed by following your web
            browser help file directions. Session cookies are temporary and
            typically disappear after you close your browser. You may be able to
            reset your web browser to refuse all cookies or to indicate when a
            cookie is being sent. However, some features of the Services may not
            function properly if the ability to accept cookies is disabled.
          </p>
        </QAItem>

        <QAItem>
          <h5>Log File Information</h5>
          <p>
            {" "}
            When you use our Service, our servers may automatically record
            certain information that your device sends whenever you visit any
            website and use certain apps. These server logs may include
            information such as your web or app request, Internet Protocol
            ("IP") address, browser type, browser language, referring/exit pages
            and URLs, platform type, number of clicks, domain names, landing
            pages, pages viewed and the order of those pages, the amount of time
            spent on particular pages, the date and time of your request, and
            one or more cookies that may uniquely identify your browser.
          </p>
        </QAItem>

        <QAItem>
          <h5>Clear GIFs Information</h5>
          <p>
            When you use our Service, we may employ technology such as "clear
            GIFs" (a.k.a. Web Beacons) which are used to track the online usage
            patterns of our users. In addition, we may also use clear GIFs in
            HTML-based emails sent to our users to track which emails are opened
            by specific users.
          </p>
        </QAItem>

        <QAItem>
          <h5>Third Party Information</h5>
          <p>
            <ProjectName /> may also collect information about you from third
            parties, such as marketing partners, identity verification services,
            anti-fraud services and other service providers.
          </p>
        </QAItem>
      </Group>
      <Group id={getId(RC.THE_WAY_IN_PLAY_COLLECTS_AND_USES_YOUR_INFORMATION)}>
        <h2>
          {getTitle(RC.THE_WAY_IN_PLAY_COLLECTS_AND_USES_YOUR_INFORMATION)}
        </h2>
        <QAItem>
          <h5>Collection Methods</h5>
          <p>
            {" "}
            There are three main methods <ProjectName /> may use to collect
            information:
          </p>
          <ul>
            <li>
              a. We collect information through your use of the Services. In
              other words, when you are using our Services, we are aware of it
              and may gather the information relating to such usage.
            </li>
            <li>
              b. We collect information which you provide us voluntarily. For
              example, we collect Personal Information which you provide when
              you respond to communications from us, set up your betting
              account, interact with our customer support and post or upload
              User Submissions through the Services.
            </li>
            <li>
              c. We collect information through third party platforms (e.g.
              Facebook, Google) when you connect to our Services via these
              platforms.
            </li>
          </ul>
          <h5>Operations Use</h5>
          <p>
            We may use your personal information to operate, maintain, and
            provide to you the features and functionality of the Services,
            including but not limited to the following:
          </p>
          <ul>
            <li>
              a. providing you with our products and services, including our
              games;
            </li>
            <li>b. processing and responding to enquiries;</li>
            <li>c. personalizing your use of the Service,</li>
            <li>
              d. alerting you to new features, special events, products and
              services, or certain third-party products or services in which we
              think you will be interested;
            </li>
            <li>
              e. enforcing the legal terms that govern your use of the Services;
              and
            </li>
            <li>
              f. investigating and protecting the integrity of <ProjectName />
              ’s contests.
            </li>
          </ul>
          <h5>Marketing Use</h5>
          <p>
            We may use your information (both personal and non-personal
            information) to send you marketing and advertising content,
            including sending you advertising through multiple channels, such as
            direct mail, email, push notifications and display media. We may
            send you advertising or content regarding our products and services,
            or products and services that we market on behalf of our Group or
            another company, such as a sports team, a sports venue, or another
            entity, which we believe may be of interest to you.
          </p>
          <p>
            Out of respect for your right to privacy we provide you with
            marketing materials which provide you with the ability to decline
            receiving further email offers from us or from our business partners
            and affiliates. In addition, at any time, you may request to
            unsubscribe and discontinue receiving marketing offers by sending a
            blank message with the word "remove" to <LinkMailToSupport />. If
            you unsubscribe we will remove your email address from our marketing
            distribution lists and from any future lists we may share with our
            marketing partners. Please note that even if you unsubscribe from
            our marketing mailing list, we may continue to send you
            service-related updates and notifications. You hereby acknowledge
            and agree that by downloading, installing or accessing our Services,
            we may share your personal information with our trusted partners for
            the purpose of e-mail marketing
          </p>
          <h5> Communication and Service Improvement</h5>
          <p>We may use your information to communicate with you about:</p>
          <ul>
            <li>
              {" "}
              a. our products and services in which you may be interested
              provided that you have not requested otherwise;
            </li>
            <li>
              {" "}
              b. newsletters and information for which you have signed up; and
            </li>
            <li>
              {" "}
              c. non-marketing or administrative purposes (such as notifying you
              of major changes to the Service or for customer service purposes).
            </li>
          </ul>
          <p>
            We use your information to improve the quality and design of our
            Services and to create new features, promotions, functionality, and
            services such as by storing, tracking, and analyzing user
            preferences and trends.
          </p>
          <h5>Cookies</h5>
          <p>
            We and our trusted partners use cookies and other technologies (e.g.
            pixels) when you access our Services. A "cookie" is a small piece of
            information which is assigned to your device while you are browsing
            the internet. Cookies are very helpful and can be used for various
            different purposes. These purposes include allowing you to navigate
            between pages efficiently, enabling automatic activation of certain
            features, remembering your preferences and making the interaction
            between you and our Services quicker and easier. Cookies are also
            used to help ensure that the advertisements you see are relevant to
            you and your interests and to compile statistical data on your use
            of our Services.
          </p>
          <p>
            <ProjectName /> uses information gathered by cookies only to analyze
            the use of our website to help guide improvements. We also use
            cookies, clear gifs and log file information such as device
            identifiers for purposes such as (a) remembering information so that
            you will not have to re-enter it during your visit or the next time
            you visit our Service; (b) providing custom, personalized content
            and information; (c) monitoring the effectiveness of our marketing
            campaigns; (d) monitoring aggregate metrics such as total number of
            visitors, pages viewed, etc.; and (e) tracking your entries,
            submissions, and status in promotions, sweepstakes, and contests. We
            do not collect any personally identifiable information.
          </p>
          <p>
            We also use a tool called “Google Analytics” to collect information
            about your use of the Services. Google Analytics collects
            information such as how often users access the Services, what pages
            they visit when they do so, etc. We use the information we get from
            Google Analytics only to improve our Services. You can learn more
            about how these technologies are used in the Section below, Third
            Party Advertising and Analytics
          </p>
          <h5>Legitimate Business Purposes</h5>
          <p>
            We may anonymize, de-identify, or aggregate your information for any
            legitimate business purposes, including for reporting and research,
            or advertising. Such anonymized and/or aggregated data does not
            personally identify you.
          </p>
          <h5>Retention of Information</h5>
          <p>
            We will retain personal information as long as necessary to fulfill
            these purposes unless the law requires us to keep it for a longer
            period of time. To provide security and business continuity for the
            activities described here, we make backups of certain data, which we
            may retain for longer than the original data.
          </p>
        </QAItem>
      </Group>
      <Group id={getId(RC.HOW_IN_PLAY_DISCLOSES_INFORMATION)}>
        <h2>{getTitle(RC.HOW_IN_PLAY_DISCLOSES_INFORMATION)}</h2>
        <p>
          <ProjectName /> does not rent, sell, or share your Personal
          Information with third parties except as described in this Privacy
          Policy. In addition to the different uses listed above,{" "}
          <ProjectName /> may transfer or disclose your information in the
          following ways.{" "}
        </p>
        <QAItem>
          <h5>Processing</h5>
          <p>
            We may disclose the information we collect or receive from you to
            our subsidiaries, affiliated companies, agents, or other businesses,
            or service providers who process your information on our behalf in
            order to provide or operate the Services. For instance, these
            entities may have access to your information for purposes including
            (but not limited to) billing and accounting, hosting, marketing,
            promotional and email services, customer and technical support, and
            for the purpose of accepting deposits and paying withdrawals and
            processing such information on our behalf, and in each case, may use
            the information to make improvements to the services they provide to
            us. Our agreements with these service providers limit the kinds of
            information they can use or process and require them to use
            reasonable efforts to keep your personal information secure.
          </p>
        </QAItem>
        <QAItem>
          <h5>Social Media</h5>
          <p>
            When you choose to share information with social media services
            about your activities on <ProjectName /> by, for example, connecting
            your social media account to our Services, then <ProjectName /> may
            share your personal information and information about your use of
            the Services with such social media sites. Please make sure to read
            the terms and privacy statements of such sites prior to connecting
            to our Service in this way.
          </p>
        </QAItem>

        <QAItem>
          <h5>Contests and Profile</h5>
          <p>
            In the event that you win a prize we may use your personal
            information (such as your name and photo) in connection with
            publicity purposes.
          </p>
          <p>
            In the event that you win a promotion run by us we may be required
            to either publish or make available upon request your name and
            county in accordance with applicable legislation.
          </p>
          <p>
            {" "}
            We may publish a profile page that includes your username, the date
            you became a member, your stats from previous contests and, if you
            uploaded one, your profile picture. Where you post personal
            information about yourself through our Service to public areas of
            our Service, you acknowledge and agree that this personal
            information will be publically available.{" "}
          </p>
          <p>
            When you participate in a contest, we may publish your username on a
            list of the contest participants, along with a link to your profile
            page.
          </p>
          <p>
            Where you have provided your consent, we may share your personal
            information with selected third parties for their commercial or
            marketing use in conjunction with your relationship with{" "}
            <ProjectName /> or as part of a specific program or feature.
          </p>
        </QAItem>

        <QAItem>
          <h5> Third Parties</h5>
          <p>
            <ProjectName /> may transfer or disclose personal information to
            companies within our group of subsidiaries, affiliated companies and
            subcontractors (“the Group”). We may also transfer or disclose
            personal information to other trusted third party service providers
            or partners who are located in different jurisdictions across the
            world, for the purpose of: (i) authenticating your identity and
            verifying your account details; (ii) storing or processing personal
            information on our behalf (e.g. cloud computing service providers);
            (iii) assisting us with our business operations and in providing and
            improving our Services; (iv) performing research, technical
            diagnostics and analytics with regard to the Services; and (v)
            communicating promotional and informational materials, in accordance
            with our marketing policy (see below under “Marketing”). In
            addition, we may disclose to third parties various types of your
            information for targeted online advertising, as further described in
            the Section titled “Third Party Advertising and Analytics.”
          </p>
          <p>
            Our policy only addresses the use and disclosure of information we
            collect from you. To the extent that you disclose your information
            to other parties via our Services (e.g. by clicking on a link to
            other website or application) or via other sites or applications,
            different rules may apply to their use or disclosure of the
            information you disclose to them. It is important to note that such
            third-party services may have their own privacy policies and we
            advise you to read them carefully. Inclusion of a link to third
            party website, application or service is not a recommendation of
            such a website or service. Third party sites may contain information
            or service that is illegal, unreasonable or that some people may
            find inappropriate or offensive.
          </p>
          <p>
            Before using third party sites, applications or services, we
            recommend you to read and understand those sites’ and service’ terms
            and conditions, warranty, and privacy policies and to ensure you
            agree to their terms. You acknowledge that <ProjectName /> is not
            responsible for the products, services, or descriptions of those
            products or services that you receive from third party sites or
            applications or to the content or privacy practices of those sites,
            and that this Privacy Policy does not apply to any such third-party
            products and services. You are knowingly and voluntarily assuming
            all risks of using third party sites or applications to purchase
            products and services. You agree that <ProjectName /> will have no
            liability whatsoever with respect to such third-party sites,
            applications or services and your usage of them.
          </p>
        </QAItem>

        <QAItem>
          <h5>Enforcement and Legal Proceedings</h5>
          <p>
            {" "}
            <ProjectName /> also reserves the right to disclose personal
            information that <ProjectName /> believes, in good faith, is
            appropriate or necessary to enforce our Terms of Use, take
            precautions against liability or harm, to investigate and respond to
            third-party claims or allegations, to respond to court orders or
            official requests, to comply with state and federal regulations,
            including but not limited to sharing with appropriate tax
            authorities, to protect the security or integrity of our Service, to
            report violations of <ProjectName />
            ’s eligibility rules to third parties, including employers of
            individuals affiliated with other daily fantasy sites, and to
            protect the rights, property, or safety of <ProjectName />, our
            users or others.
          </p>
        </QAItem>

        <QAItem>
          <h5>Corporate Changes</h5>
          <p>
            In the event that <ProjectName /> is involved in a merger,
            acquisition, sale, bankruptcy, insolvency, reorganization,
            receivership, assignment for the benefit of creditors, or the
            application of laws or equitable principles affecting creditors'
            rights generally, or other change of control, there may be a
            disclosure of your personal information to another entity related to
            such event.
          </p>
        </QAItem>

        <QAItem>
          <h5>Disclaimer</h5>
          <p>
            Recipients of the data disclosures described in this Privacy Policy
            are located in the United States and elsewhere in the world,
            including where privacy laws may not provide as much protection as
            your country.
          </p>
        </QAItem>
      </Group>
      <Group id={getId(RC.RETENTION_OF_COLLECTED_INFORMATION)}>
        <h2>{getTitle(RC.RETENTION_OF_COLLECTED_INFORMATION)}</h2>
        <p>
          We value your privacy and control over your Personal Information, and
          therefore you may, at any time, request to change and update it by
          emailing us at <LinkMailToSupport />. You can also request that we
          correct errors or that we erase your personal information (except for
          your transaction history and other data that we are required to keep
          under applicable laws) by emailing us at
          <LinkMailToSupport />. Please note that unless you instruct us
          otherwise, we retain the information we collect to provide the
          Services and to comply with our legal obligations, resolve disputes
          and enforce our agreements. We may rectify, replenish or remove
          incomplete or inaccurate information, at any time and at our own
          discretion.
        </p>
      </Group>
      <Group id={getId(RC.SAFEGUARD_AND_TRANSFER_OF_YOUR_INFORMATION)}>
        <h2>{getTitle(RC.SAFEGUARD_AND_TRANSFER_OF_YOUR_INFORMATION)}</h2>
        <p>
          We take great care in implementing and maintaining the security of the
          Services and your information. We employ industry standard procedures
          and policies to ensure the safety of our users’ information, and to
          prevent unauthorized use of any such information. Although we take
          reasonable steps to safeguard information, we cannot be responsible
          for the acts of those who gain unauthorized access or abuse our
          Services, and we make no warranty, express, implied or otherwise, that
          we can prevent such unauthorized access. Since we operate globally, it
          may be necessary to transfer your personal information to countries
          outside the United States. It is our practice to comply with the
          privacy laws of any jurisdiction where we operate.
        </p>
        <p>
          The data protection and other laws of these countries may not be as
          comprehensive as those in the United States or European Union. In
          these instances, <ProjectName /> will take steps to ensure that a
          similar level of protection is given to your personal information. You
          hereby consent to transfer of your personal information to countries
          outside the United States. If you feel that your privacy was not
          treated in accordance with our Privacy Policy, or if any person
          attempted to abuse our Services or acted in an inappropriate manner,
          please contact us directly at <LinkMailToSupport />
        </p>
      </Group>
      <Group id={getId(RC.LEGAL_BASES_FOR_PROCESSING_PERSONAL_DATA)}>
        <h2>{getTitle(RC.LEGAL_BASES_FOR_PROCESSING_PERSONAL_DATA)}</h2>
        <p>
          Some laws require companies to tell you about the legal grounds they
          rely on to process your personal information. To the extent those laws
          apply, we process your personal information:
        </p>
      </Group>
      <Group id={getId(RC.LEGITIMATE_INTERESTS)}>
        <h2>{getTitle(RC.LEGITIMATE_INTERESTS)}</h2>
        <p>
          In many cases, we handle personal data on the ground that it furthers
          our legitimate interests’ in commercial activities such as the
          following in ways that are not overridden by the interests or
          fundamental rights and freedoms of the affected individuals:
        </p>
        <ul>
          <li> a. Customer service</li>
          <li> b. Marketing</li>
          <li> c. Protecting our players, personnel and property</li>
          <li> d. Analyzing and improving our business</li>
          <li> e. Processing job applications</li>
          <li> f. Managing legal issues</li>
        </ul>
        <QAItem>
          <h5>Business Affiliates</h5>
          <p>
            {" "}
            We may also process personal data for the legitimate interests of
            our affiliates or business partners, such as to offer special events
            or contests or assist with marketing.
          </p>
        </QAItem>

        <QAItem>
          <h5>Processing Contracts</h5>
          <p>
            Some of our processing of personal data is to meet our contractual
            obligations with our players.
          </p>
        </QAItem>

        <QAItem>
          <h5>Consent</h5>
          <p>
            Where required by law, and in some other cases, we handle personal
            data on the basis of your implied or express consent, as applicable.
          </p>
        </QAItem>

        <QAItem>
          <h5>Legal Compliance</h5>
          <p>
            {" "}
            We need to use and disclose personal data in certain ways to comply
            with our legal obligations.
          </p>
        </QAItem>
      </Group>
      <Group id={getId(RC.YOUR_CHOICES)}>
        <h2>{getTitle(RC.YOUR_CHOICES)}</h2>
        <p>
          <ProjectName /> will process your personal information in accordance
          with this Privacy Policy, and as part of that <ProjectName /> provides
          you with certain choices about how we process your personal
          information. These choices are set out below.
        </p>
        <p>
          You can access and update certain personal information <ProjectName />{" "}
          holds about you at any time by logging into your account via the
          Service. You can update your marketing preferences and whether or not
          you would like us to send you newsletters in your account. You can
          also unsubscribe to marketing emails and newsletters by using the
          'unsubscribe' feature in the communication.
        </p>
        <p>
          We may occasionally send display media to you, in a targeted way via
          the web. You may opt out of many third parties that support and send
          this type of targeting advertising by going to <ProjectName url />,
          and you may learn more about this type of advertising in the below
          Section titled “Third Party Advertising and Analytics.”
        </p>
        <p>
          You can reach us as described above with any other concerns, requests
          or complaints regarding our data collection, data use or data
          transfer. You also have a right to file a privacy complaint with the
          relevant privacy regulator, but we respectfully invite you to allow us
          to try to resolve the matter directly. We will attempt to answer your
          questions and satisfy your concerns in a timely and complete manner.
        </p>
      </Group>
      <Group id={getId(RC.THIRD_PARTY_ADVERTISING_AND_ANALYTICS)}>
        <h2>id={getTitle(RC.THIRD_PARTY_ADVERTISING_AND_ANALYTICS)}</h2>
        <p>
          Our advertising partners may use cookies and other similar
          technologies, often in conjunction with unique (anonymous) cookie
          identifiers, to collect data about your activities and interests,
          including the technologies described above. Doing this allows the ad
          companies to recognize your computer each time they send you an online
          advertisement. In this way, the ad companies may compile information
          about where you, or others who are using your computer, saw their
          advertisements and measure your interaction with each ad. This
          information allows ad companies to deliver targeted advertisements
          that may be of increased interest to you, both on our Service and
          elsewhere online. Just as these technologies may be used to target ads
          on our websites, we, or our advertising partners, may use these same
          technologies and data points (e.g., cookie identifiers, location-based
          data), through our Services or through other services, to target
          advertising (for ourselves or other companies) on other sites.
          Sometimes, these identifiers may be derived from a hashed or encrypted
          version of personal information such as your email address. We may use
          this information to measure the performance of our advertising as
          well, for instance, to evaluate which ads or content our users prefer,
          or which are most effective.
        </p>
        <p>
          To learn more and to opt out of the collection of data on our website
          by third parties (including those described above) for interest-based
          advertising purposes, please visit www.
          <ProjectName />
          .io. Note that because this opt-out method may be cookie-based, you
          will need to opt out again if you update or change your browser, or
          delete your cookies.
        </p>
        <p>
          We also that we also work with third-party analytics companies to
          collect data on our Service through cookies and other automated means.
          In some cases, the third parties mentioned in this section may
          maintain the information they collect in personally identifiable form.
        </p>
      </Group>
      <Group id={getId(RC.INFORMATION_SECURITY)}>
        <h2>{getTitle(RC.INFORMATION_SECURITY)}</h2>
        <p>
          We are committed to protecting the security of your personal
          information. We use a variety of security technologies and procedures
          to help protect your personal information from unauthorized access,
          use, or disclosure.
        </p>
        <p>
          While neither we, nor any other organization, can guarantee the
          security of information processed online, we do have appropriate
          security measures in place to protect your personal information. For
          example, we store the personal information you provide on computer
          systems with limited access that are located in facilities to which
          access is limited.
        </p>
        <p>
          To further protect your privacy and security, we reserve the right to
          take reasonable steps (such as requesting your password) to verify
          your identity before granting you profile access or making
          corrections.
        </p>
        <p>
          Additionally, you should ensure your password is kept secret at all
          times. You will be solely responsible for maintaining the secrecy of
          your password and account information.
        </p>
      </Group>
      <Group id={getId(RC.YOUR_CALIFORNIA_PRIVACY_RIGHTS)}>
        <h2>{getTitle(RC.YOUR_CALIFORNIA_PRIVACY_RIGHTS)}</h2>
        <p>
          If you reside in California, you may request certain general
          information regarding our disclosure of personal information to third
          parties for their direct marketing purposes. To make such a request,
          please write to us at the following address:
        </p>
        <p>
          <ProjectName company />{" "}
        </p>
        <p>
          <ProjectName address /> <LinkMailToSupport />
        </p>
      </Group>
      <Group id={getId(RC.MINORS)}>
        <h3>{getTitle(RC.MINORS)}</h3>
        <p>
          The Services are not designated to users under the age of 18. If you
          are under 18, you should not download or use the Services nor provide
          any Personal Information to us. We reserve the right to access and
          verify any Personal Information collected from you. In the event that
          we become aware that a user under the age of 18 has shared any
          information, we will discard such information, immediately terminate
          the minor’s account and refund any applicable funds. If you have any
          reason to believe that a minor has shared any information with us,
          please contact us at
          <LinkMailToSupport />
        </p>
      </Group>
      <Group id={getId(RC.CHANGES_AND_UPDATES)}>
        <h2>{getTitle(RC.CHANGES_AND_UPDATES)}</h2>
        <p>
          This Privacy Policy may be revised periodically. Please revisit this
          page to stay aware of any changes. If a revision to this Privacy
          Policy, in our sole discretion, is material, we will notify you by
          contacting you through the email address associated with your account.
        </p>
        <p>
          Contact Information: Please contact <ProjectName /> with any questions
          or comments about this Privacy Policy by emailing{" "}
          <LinkMailToSupport />
        </p>
      </Group>
    </Layout>
  )
}

export default PrivacyPolicy
