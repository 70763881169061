import React from "react"
import { getProjectMetadata } from "../../utils/projectMetadataUtil"
import { env } from "../utils/evnVariablesHandlers"

const { url, title } = getProjectMetadata(env.__PROJECT__)

const ProjectLink = ({ strict }) => {
  return (
    <a href={url} target="_blank">
      {strict ? url : title}
    </a>
  )
}

export default ProjectLink
